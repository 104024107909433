<template>
  <div class="events">
    <div class="padding_top"></div>

    <b-container>
      <b-row class="page_header" align-h="center">
        <b-col cols="auto">
          <h1 class="page_title">Мероприятия</h1>
          <p class="page_subtitle" v-if="view == 'all'">Все мероприятия</p>
          <p class="page_subtitle" v-if="view == 'my'">Мои мероприятия</p>
        </b-col>
      </b-row>

      <b-row v-if="events && view == 'all'">
        <b-col cols="12" md="4" v-for="(event, index) in events" :key="index">
          <EventCard :event="event" buttonText="подробнее" :index="index" />
        </b-col>
      </b-row>

      <b-row v-if="myEvents && view == 'my'">
        <b-col cols="12" md="4" v-for="(event, index) in myEvents" :key="index">
          <EventCard
            :event="event.event"
            buttonText="подробнее"
            :index="index"
          />
        </b-col>
      </b-row>
    </b-container>

    <EventModal />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EventCard from "@/components/events/EventCard.vue";
import EventModal from "@/components/events/EventModal.vue";

export default {
  name: "Events",
  components: {
    EventCard,
    EventModal,
  },
  methods: {
    ...mapActions(["getEvents", "getMyEvents", "getUser"]),
  },
  computed: {
    events() {
      return this.$store.state.events.events;
    },
    myEvents() {
      return this.$store.state.events.myEvents;
    },
    view() {
      return this.$store.state.events.view;
    },
  },
  async mounted() {
    try {
      this.$store.commit("SET_LOADING", true);

      if (localStorage.getItem("token")) {
        await this.getUser();
      }

      await this.getEvents();
      await this.getMyEvents();

      this.$store.commit("SET_LOADING", false);
    } catch (e) {
      this.$store.commit("SET_LOADING", false);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.title-modal {
  font-size: 22px;
  padding-bottom: 30px;
}
.description-event-modal {
  font-size: 18px;
  padding-bottom: 15px;
  font-weight: 400;
}
</style>
